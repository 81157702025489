<template>
    <update-form
        type="audits"
        :survey-parts="$parent.surveyParts"
        :fields="fields"
    />
</template>

<script>
    import UpdateForm from "@/components/data/surveryPart/UpdateForm";

    export default {
        components: {
            UpdateForm
        },
        computed: {
            fields: function() {
                return [
                    {
                        key: 'lastInternAuditWorkingConditions',
                        label: this.$t('When was the last internal review of working conditions and stresses conducted?'),
                        //label: 'Wann erfolgte zuletzt eine interne Überprüfung der Arbeitsbedingungen und -Belastungen?',
                        type: 'date',
                    },
                    {
                        key: 'lastInternAuditMentalConditions',
                        label: this.$t('When was the last internal review of workloads with regard to mental hazards?'),
                        //label: 'Wann erfolgte zuletzt eine interne Überprüfung der Arbeitsbelastungen hinsichtlich psychischer Gefährdungen (GBpsych)?',
                        type: 'date',
                    },
                    {
                        key: 'lastExternAuditHealth',
                        label: this.$t('When was the last assessment/auditing by an external body (e.g. BG, TÜV, district government) of an existing health management system (Occupational Health and Safety Management System etc.)?'),
                        //label: 'Wann erfolgte zuletzt eine Begutachtung/ Auditierung durch eine externe Stelle (z.B. BG, TÜV, BezReg.) zu einem bestehenden Gesundheitsmanagementsystem (ASM, <u>BGM</u>S etc.)?',
                        type: 'date',
                    },
                    {
                        key: 'lastDatesHealthAndSafetyCommitteeMeetings',
                        label: this.$t('When were the last four Occupational Safety and Health Committee meetings held?'),
                        //label: 'Wann fanden die letzten vier Arbeitsschutzausschusssitzungen (ASA) statt?',
                        type: 'multipleDates',
                    },
                    {
                        key: 'nextDateHealthAndSafetyCommitteeMeeting',
                        label: this.$t('When is the next Occupational Health and Safety Committee meeting?'),
                        //label: 'Wann findet die nächste Arbeitsschutzausschusssitzung (ASA) statt?',
                        type: 'date',
                    },

                ];
            }
        }
    }
</script>
